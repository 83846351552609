import React from 'react';
import IntroParagraph from '../components/IntroParagraph';

/**
 * Main page of the Performance.utah.gov website
 */
const PerformanceVisualizationPage = () => {
  return (
    <>
      <IntroParagraph/>
      <iframe src="https://public.domo.com/embed/pages/lODmM" width="1248" height="1620" marginHeight="0"
              marginWidth="0" frameBorder="0"></iframe>
    </>
  );
}

export default PerformanceVisualizationPage;
