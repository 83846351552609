import React from 'react';
import { Link } from 'react-router-dom'
import constants from "../constants";
import seal from '../../static/img/Seal_of_Utah.svg';
import headerLogo from '../../static/img/performance_header.svg';
import {AppBar, Toolbar, Typography, Button, Box, List, ListItem, ListItemText, IconButton, Menu, MenuItem, Fade, Drawer, Divider} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {useHistory} from "react-router";

const Header = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [apiExpanded, setApiExpanded] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawerEvent = (open) => (event) => {
    console.log(1);
    setDrawerOpen(open);
  };
  const toggleDrawer = (open) =>{
    console.log(2);
    setDrawerOpen(open);
  };
  const navigatePageFromDrawer = (path) => {
    toggleDrawer(false);
    setApiExpanded(false);
    history.push(path);
  }
  return (
    <div className='header-container' style={{display: 'flex'}}>
      <AppBar position="relative" style={{ background: 'none', color: '#0f2652'}}>
        <Toolbar style={{ padding: '0'}}>
            <Box sx={{display: 'flex', justifyContent: {xs: 'center', md:'space-between'}, width: '100%', padding: {sm: '0 5%', md: '0 10%'}, margin: {xs:'0.75em 0', md:'1.25em 0'}}}>
              <Box sx={{display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton
                      size="large"
                      color="inherit"
                      sx={{marginRight: {sx:'0.5em', sm:'1em'}}}
                      onClick={toggleDrawerEvent(!drawerOpen)}
                    >
                      <MenuIcon />
                    </IconButton>
                    </Box>
              <Box sx={{display: { xs: 'flex', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
                  <img src={headerLogo} className={'header-container__image'} onClick={() => history.push('/')}/>
                </Box>
                <Box sx={{display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                  <Button onClick={() => history.push('/')} style={{color: '#0f2752', fontWeight: '700', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '20px', marginRight: '2em' }}>
                    <Typography variant={'h2'} style={{color: '#0f2752', fontWeight: '700', fontSize: '1.2rem', margin: '0.25em' }}>
                      HOME
                    </Typography>
                  </Button>
                  <Box sx={{marginRight: '2em', marginTop: '0.25em', display: 'flex', alignItems: 'center'}}>
                    <Typography variant={'h2'} style={{color: '#0f2752', fontWeight: '700', fontSize: '1.2rem', cursor: 'pointer'}} onClick={handleClick}>
                      API
                    </Typography>
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={handleClick}
                    >
                      <KeyboardArrowDownRoundedIcon sx={{color: '#0f2752'}}/>
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      TransitionComponent={Fade}
                      style={{marginTop: '0.5em'}}
                    >
                      <MenuItem onClick={() => {window.location.href = 'https://utahdts.github.io/gopb-performance-website/'; handleClose();}}>Endpoint Documentation</MenuItem>
                      <MenuItem onClick={() => {history.push('/apiDocs'); handleClose();}}>Data Dictionary</MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'top'}
        sx={{
          width: '240',
          marginTop: '10em',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '240', boxSizing: 'border-box' },
        }}
        open={drawerOpen}
        onClose={toggleDrawerEvent(false)}
      >
        <Box
          sx={{ width: 'auto', overflow: 'auto' }}
          role="presentation"
        >
          <List>
              <ListItem button onClick={() => { navigatePageFromDrawer('/')}}>
                <ListItemText primary={'Home'}/>
              </ListItem>
              <ListItem button onClick={() => {setApiExpanded(!apiExpanded)}}>
                <ListItemText primary={'API'} />
              </ListItem>
              {
                apiExpanded && <>
                  <ListItem style={{paddingLeft:'10%'}} button onClick={() => {window.location.href = 'https://utahdts.github.io/gopb-performance-website/';}}>
                    <ListItemText primary={'Endpoint Documentation'} />
                  </ListItem>
                  <ListItem style={{paddingLeft:'10%'}} button onClick={() => navigatePageFromDrawer('/apiDocs')}>
                    <ListItemText primary={'Data Dictionary'} />
                  </ListItem>
                </>
              }
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default Header;
