import React from 'react';
import FooterColumn from './FooterColumn';
import seal from '../../static/img/Seal_of_Utah.svg';
import constants from '../../js/APIObjectConstants';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, ButtonGroup, Button} from '@mui/material';
import OptionList from './OptionList'

/**
 * The Data dictionary page for the Performance API
 */
const DataDictionary = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleChange = (event, newIndex) => {
    setActiveIndex(newIndex);
  };
  return (
    <div className='dataDictionary-container'>
      <Typography variant={'h5'} sx={{margin:'auto', textAlign:'center', marginBottom: '0.5em'}}>Object Types</Typography>
      <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <ButtonGroup variant="text" aria-label="text button group" sx={{margin: 'auto'}}>
          {
            constants.objects.map((object,index) => {
              if (index < 4) { return <Button style={{textTransform: 'none'}} key={index} onClick={(e) => handleChange(e, index)}>{object.name}</Button>;}})
          }
        </ButtonGroup>
        <br/>
        <ButtonGroup variant="text" aria-label="text button group" sx={{margin: 'auto'}}>
          {
            constants.objects.map((object,index) => {
              if (index >= 4) { return <Button style={{textTransform: 'none', marginBottom: '1em'}} key={index} onClick={(e) => handleChange(e, index)}>{object.name}</Button>;}})
          }
        </ButtonGroup>
      </div>
      <Typography variant={'h6'} sx={{margin:'0.5em 0'}}>The {constants.objects[activeIndex].name} Object</Typography>
      <Typography variant={'body1'}>{constants.objects[activeIndex].description}
        {
          constants.objects[activeIndex].link1 && constants.objects[activeIndex].description2 &&
          <>
            <span dangerouslySetInnerHTML={{ __html: constants.objects[activeIndex].link1 }} />
            <span variant={'body1'}>{constants.objects[activeIndex].description2}</span>
          </>
        }
      </Typography>
      <TableContainer>
        <Paper elevation={3} sx={{ width:'95%', margin: 'auto'}}>
          <Table sx={{ width:'100%', margin: '2em auto'}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Field Value</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {constants.objects[activeIndex].fields.map((row) => (
                <TableRow
                  key={row.field}
                >
                  <TableCell component="th" scope="row">
                    {row.field}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    {row.description}
                    {
                      row.options && <OptionList options={row.options}/>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </div>
  );
}

export default DataDictionary;
