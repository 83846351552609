import React from 'react';
import {Typography, Link} from '@mui/material';

const Introduction = () => {
  return (
    <div className='introduction-container' style={{width:'80%', margin: 'auto'}}>
      <Typography variant={'body1'} sx={{marginBottom:'1em'}}>Welcome to the data dictionary wiki of Utah's Performance API! This data dictionary goes
        into detail about each of the objects that you'll encounter within the API. The API endpoint documentation can be found <Link href={'https://utahdts.github.io/gopb-performance-website/'}>here</Link>.
      </Typography>
      <Typography variant={'body1'}>This API is within its very early stages,
        so please provide any feedback if the API and its documentation is not intuitive or easy to work with.
        If you want to submit feedback or ask for help, please contact the API's primary maintainer at brysonoar@utah.gov.
      </Typography>
    </div>
  );
}

export default Introduction;
