
import React from "react";
import {render} from "react-dom";
import "../../css/index_common.scss";
import Footer from '../components/Footer';
import seal from '../../static/img/Seal_of_Utah.svg';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PerformanceVisualizationPage from '../components/PerformanceVisualizationPage';
import DataDictionaryPage from '../components/DataDictionaryPage';
import Header from '../components/Header';

const propTypes = {};
const defaultProps = {};

const App = () => (
  <>
    <main className="container">
      <Router>
        <Header/>
        <Switch>
          <Route path="/" exact component={PerformanceVisualizationPage}/>
          <Route path="/apiDocs" exact component={DataDictionaryPage}/>
          <Route path='/api' component={() => {
            window.location.href = 'https://utahdts.github.io/gopb-performance-website/';
            return null;
          }}/>
        </Switch>
      </Router>
    </main>
    <Footer/>
  </>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

render(<App/>, document.getElementById('react'));
