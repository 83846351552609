import React from 'react';
// Object definitions for each Performance API object
export default {
  objects: [
    {
      name: 'Performance Measure',
        description: 'The performance measure (PM) object is central to this API. Each PM object represents a single performance measure with ' +
          'high level information of what it is tracking. PM objects contain several lists that identify what ' +
      'type of PM it is. Performance measures are linked to line items (from the budget), which can be found under the pmLineItemLookups list ' +
      'in this object. Use the PM\'s pmId within some of the API\'s endpoints to query Budget data objects and PmEntry ' +
      'data entry objects specific to this PM.',
        fields: [
        {
          field: 'pmId',
          type: 'Integer',
          description: 'The unique identifier of a Performance Measure.',
        },
        {
          field: 'creationDate',
          type: 'LocalDateTime',
          description: 'The date that the PM was originated in the system',
        },
        {
          field: 'description',
          type: 'String',
          description: 'A description of the performance measure.',
        },
        {
          field: 'embodiesAgencyPrimaryObjective',
          type: 'Boolean',
          description: 'Indicates if this PM is central to the agency\'s primary objective/ goals.',
        },
        {
          field: 'firstSubmitDate',
          type: 'LocalDateTime',
          description: 'The first date for which there will be data to submit as a PmEntry.',
        },
        {
          field: 'fundingItemName',
          type: 'String',
          description: 'If the PM is associated with a funding item, then this field will have that funding item\'s name.',
        },
        {
          field: 'measureCategory',
          type: 'String',
          description: 'Indicates whether the PM falls under one of five categories of what is being measured',
          options: ['Population outcome', 'Organization outcome', 'Organization productivity', 'Organization backlog', 'Organization status update']
        },
        {
          field: 'measureFormat',
          type: 'String',
          description: 'A string that indicates what type of format the PM\'s target data represents. For example, if a target is "50" ' +
            'and the format is $, then the target is $50. If the format is %, then the target is 50%',
          options: ['#', '$', '%', 'text']
        },
        {
          field: 'measureJustification',
          type: 'String',
          description: 'Explains the purpose for reporting the measure.',
        },
        {
          field: 'name',
          type: 'String',
          description: 'The name of the performance measure.',
        },
        {
          field: 'notes',
          type: 'String',
          description: 'Any additional notes about the PM and how it is calculated.',
        },
        {
          field: 'organizationalUnit',
          type: 'object',
          description: 'The object that indicates which organizational unit the PM falls under.',
        },
        {
          field: 'pmAgency',
          type: 'object',
          description: 'The object for the agency that manages this PM.',
        },
        {
          field: 'pmGovInitiativesAlignments',
          type: 'array',
          description: 'The array of PmGovInitiativesAlignment objects associated with this PM.',
        },
        {
          field: 'pmImpactAreas',
          type: 'array',
          description: 'The array of PmImpactArea objects associated with this PM.',
        },
        {
          field: 'pmLineItemLookups',
          type: 'array',
          description: 'The array of PmLineItemLookups objects associated with this PM.',
        },
        {
          field: 'pmMeasureTypes',
          type: 'array',
          description: 'The array of PmMeasureType objects associated with this PM.',
        },
        {
          field: 'pmStatus',
          type: 'object',
          description: 'A PmStatus object ID which states if the PM is active or archived.',
        },
        {
          field: 'submissionFrequency',
          type: 'String',
          description: 'How often this PM is intended to be reported on.',
          options: ['Once', 'Monthly', 'Quarterly', 'Annually', 'Fiscally']
        },
      ]
    },
    {
      name: 'Organizational Unit',
        description: 'The organizational unit object\'s purpose is to show what subdivision of an agency this performance ' +
      'measure applies to. These subdivisions are often a division, program, or project under the agency, but may also be an external' +
      ' entity that works with the performance measure\'s presiding agency.',
        fields: [
        {
          field: 'organizationalUnitId',
          type: 'Integer',
          description: 'The unique identifier of an organizational unit.'
        },
        {
          field: 'divisionName',
          type: 'String',
          description: 'The name of the division or bureau, if applicable.'
        },
        {
          field: 'externalEntityName',
          type: 'String',
          description: 'The name of the external entity, if applicable. Performance measures are created for external entities (such as organizations or businesses) ' +
            'that receive pass-through funding. Usually there is one agency that is responsible for reporting the performance for this funding.'
        },
        {
          field: 'pmAgencyId',
          type: 'Integer',
          description: 'The ID for the agency that this organizational unit is under.'
        },
        {
          field: 'programName',
          type: 'String',
          description: 'The name of the program or project, if applicable.'
        },
        {
          field: 'unitType',
          type: 'String',
          description: 'The type of organizational unit this object represents.',
          options: ['programOrProject', 'divisionOrBureau', 'externalEntity', 'entireAgency']
        },
      ]
    },
    {
      name: 'Agency',
        description: 'The agency object contains high-level identifying info about a specific agency within Utah.',
        fields: [
        {
          field: 'id',
          type: 'Integer',
          description: 'The unique identifier of this agency object'
        },
        {
          field: 'departmenttxt',
          type: 'String',
          description: 'The id for an agency within the Office of the Legislative Fiscal Analyst\'s system.'
        },
        {
          field: 'name',
          type: 'String',
          description: 'The name of the agency.'
        },
        {
          field: 'shortName',
          type: 'String',
          description: 'The acronym that is associated with this agency.'
        },
      ]
    },
    {
      name: 'PmGovInitiativesAlignmentType',
      description: `Governor Initiatives Alignment types are one of the ways we classify performance measures. This type of classification originates from the Cox-Henderson Administration\'s `,
      // Ideally the description is all in one description, but I could not find a way to include links into the description text
      link1: 'One Utah Roadmap'.link('https://governor.utah.gov/2021/10/20/gov-cox-and-lt-gov-henderson-release-one-utah-roadmap-update/'),
      description2: ` which outlines specific initiatives to focus on as a state.`,
      fields: [
        {
          field: 'pmGovInitiativesAlignmentTypeId',
          type: 'Integer',
          description: 'The unique identifier of the GovInitiativesAlignmentType object.'
        },
        {
          field: 'govInitiativesAlignment',
          type: 'String',
          description: 'The type of governor initiative alignment this object represents.'
        },
      ]
    },
    {
      name: 'PmMeasureType',
        description: 'Performance Measure types are one of the ways we classify performance measures.' +
      ' This type of classification was created to help identify how this performance measure relates to the budget.' +
      ' For example, a measure may originate from a funding item in the budget (\'Funding Item Measure\') and also be ' +
      'connected to a budget line item (\'Line Item Measure\')',
        fields: [
        {
          field: 'pmMeasureTypeId',
          type: 'Integer',
          description: 'The unique identifier of the PmMeasureType object.'
        },
        {
          field: 'measureType',
          type: 'String',
          description: 'The type of measure type this object represents.',
          options: ['Line Item Measure', 'COBI Measure', 'Funding Item Measure', 'Other Measure']
        },
      ]
    },
    {
      name: 'PmImpactAreaType',
        description: 'Impact Area types are one of the ways we classify performance measures.' +
      ' This type of classification aims to tag performance measures to broader impact areas within Utah. ' +
      'Some examples of impact areas are education, environment, and transportation.',
        fields: [
        {
          field: 'pmImpactAreaTypeId',
          type: 'Integer',
          description: 'The unique identifier of the PmImpactAreaType object.'
        },
        {
          field: 'impactArea',
          type: 'String',
          description: 'The type of impact area this object represents.'
        },
      ]
    },
    {
      name: 'PmStatus',
        description: 'This object is responsible for labeling what the status for a performance measure might be. ' +
      'Performance measures must first be approved by the Governor\'s Office of Planning and Budget and the Office ' +
      'of the Legislative Fiscal Analyst before they can be reported on. Performance measures waiting on approval will ' +
      'be marked as \'Pending\' whereas performance measures that have been approved (and are not archived) will have a ' +
      'status of \'Active\'.',
        fields: [
        {
          field: 'pmStatusId',
          type: 'Integer',
          description: 'The unique identifier of the PmStatus object.'
        },
        {
          field: 'isActive',
          type: 'Boolean',
          description: 'Indicates if the performance measure is actively being tracked.'
        },
        {
          field: 'isArchived',
          type: 'Boolean',
          description: 'Indicates if the performance measure has been archived.'
        },
        {
          field: 'longStatusType',
          type: 'String',
          description: 'Indicates the status of the performance measure.',
          options: ['Active', 'Archived']
        },
      ]
    },
    /*{
      name: 'PmLineItemLookup',
        description: 'The PmLineItemLookup object is designed to be one of the main links between performance measures and the budget.' +
      ' These objects link the id to a performance measure (pmId) to Appropriations and AppropriationClasses within the budget.' +
      ' Some PmEntry objects are tied to specific line items as well, so a PmEntryId can also be present if that relationship is present.',
        fields: [
        {
          field: 'pmLineItemLookupId',
          type: 'Integer',
          description: 'The unique identifier of the pmLineItemLookup object.'
        },
        {
          field: 'pmId',
          type: 'Integer',
          description: 'The pmId of the PM that is associated with this line item.'
        },
        {
          field: 'pmEntryId',
          type: 'Integer',
          description: 'The id of the PmEntry that is associated with this line item, if applicable.'
        },
        {
          field: 'appropriationId',
          type: 'Integer',
          description: 'The id of the Appropriation that is associated with this line item, if applicable.'
        },
        {
          field: 'AppropriationClasses',
          type: 'object',
          description: 'The appropriation class object that is associated with this line item.'
        }
      ]
    },*/
    {
      name: 'PmEntry',
        description: 'The PmEntry object is the data entry object for performance measures. When an agency wants to make a new' +
      ' data entry for one of their performance measures, they\'ll create one of these objects. These objects contain basic info such' +
      ' as the actual value and target of the measure at the time of reporting as well as what time of the year this data entry occured in.' +
      'The value of the actual and target will usually be a number, so be sure to check on the PM for this data entry to see what format this data' +
      ' is to be parsed as (percents, dollars, raw number, etc.).',
        fields: [
        {
          field: 'pmEntryId',
          type: 'Integer',
          description: 'The unique identifier of the pmEntry object.'
        },
        {
          field: 'annotations',
          type: 'String',
          description: 'Annotations that provide more context for this entry\'s target and value.'
        },
        {
          field: 'measureValue',
          type: 'String',
          description: 'The actual value of the PM corresponding to the reportDate.'
        },
        {
          field: 'target',
          type: 'String',
          description: 'The target for the PM corresponding to the reportDate.'
        },
        {
          field: 'pmId',
          type: 'Integer',
          description: 'The pmId of the PM that is associated with this entry.'
        },
        {
          field: 'reportDate',
          type: 'LocalDateTime',
          description: 'The date that this entry\'s values correspond to.'
        },
        {
          field: 'reportFiscalYear',
          type: 'String',
          description: 'The fiscal year that this entry corresponds to, based on the reportDate.'
        },
      ]
    },
    /*{
      name: 'Appropriation',
        description: 'The appropriation object is another object that is central to the budgeting process in Utah. An appropriation is a ' +
      'set of money that is appropriated to be used for a specific purpose, as outlined in its corresponding budget or spending bill.' +
      ' Appropriations have a collection of budget objects that detail what has been spent and what can still be spent under this appropriation.',
        fields: [
        {
          field: 'id',
          type: 'Integer',
          description: 'The unique identifier of this appropriation object'
        },
        {
          field: 'agency',
          type: 'object',
          description: 'The Agency that is over this appropriation.'
        },
        {
          field: 'apprDescription',
          type: 'String',
          description: 'A description of the appropriation.'
        },
        {
          field: 'apprKey',
          type: 'String',
          description: 'The key code for the appropriation.'
        },
        {
          field: 'appropriationClasses',
          type: 'object',
          description: 'The appropriation class object associated with this appropriation.'
        },
        {
          field: 'billCategory',
          type: 'object',
          description: 'The object that states what category of bill this appropriation falls under'
        },
        {
          field: 'budgets',
          type: 'array',
          description: 'An array of budget objects that are associated with this appropriation.'
        },
        {
          field: 'note',
          type: 'String',
          description: 'Any additional notes about this appropriation.'
        },
      ]
    },
    {
      name: 'Appropriation Classes',
        description: 'Appropriation Classes are a set of objects that are a subset of Appropriation objects. Appropriation classes are more' +
      ' precise in what programs or projects the Appropriation money is being spent on. This object contains high-level ' +
      'metadata for the Appropriation Class as well as some metadata that the LFA uses for their systems.',
        fields: [
        {
          field: 'id',
          type: 'Integer',
          description: 'The unique identifier of an appropriationClass object.'
        },
        {
          field: 'appropriationClass',
          type: 'String',
          description: 'The name of the appropriation class.'
        },
        {
          field: 'appropriationClassCode',
          type: 'String',
          description: 'The code of the appropriation class.'
        },
        {
          field: 'bpAgencyId',
          type: 'Integer',
          description: 'The id of the Agency that is over this appropriation.'
        },
        {
          field: 'lfaAgency',
          type: 'String',
          description: 'The agency departmenttxt that the LFA tracks.'
        },
        {
          field: 'lfaAgencyCobiId',
          type: 'Integer',
          description: 'The id of the Agency within the LFA\'s COBI system.'
        },
        {
          field: 'lfaLineItem',
          type: 'String',
          description: 'The line item that the LFA tracks to this appropriation.'
        },
        {
          field: 'lfaLineItemCobiId',
          type: 'Integer',
          description: 'The line item id that the LFA tracks to this appropriation.'
        },
      ]
    },
    {
      name: 'Budget',
        description: 'The budget object describes how much money was spent for an appropriation and how much has been authorized' +
      'to be spent under the appropriation. This objects also gives important insight into whether this appropriation is only ' +
      'being funded once and if the appropriation is still having money being spent from it.',
        fields: [
        {
          field: 'id',
          type: 'Integer',
          description: 'The unique identifier of a budget object.'
        },
        {
          field: 'actualsAdjusted',
          type: 'Integer',
          description: 'The actual amount spent of the appropriation.'
        },
        {
          field: 'authorizedAdjusted',
          type: 'Integer',
          description: 'The authorized amount that this appropriation can spend in total.'
        },
        {
          field: 'inactive',
          type: 'Boolean',
          description: 'Indicates whether the appropriation is still actively being spent.'
        },
        {
          field: 'oneTime',
          type: 'Boolean',
          description: 'Indicates if this budget\'s appropriation is only subject to funding once or ' +
            'if it will receive more funding in the future.'
        },
        {
          field: 'year',
          type: 'String',
          description: 'The year that this budget was spent.'
        }
      ]
    },*/
  ]
};
