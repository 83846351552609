import React from 'react';
import constants from "../constants";
import FooterColumn from './FooterColumn';
import seal from '../../static/img/Seal_of_Utah.svg';

const OptionList = ({options}) => {
  return (
    <div className='optionList-container'>
      <br/>
      Options are: <br/>
      {options.map(option => (
        <div key={option}>
          <code> - {option} </code>
        </div>
      ))}
    </div>
  );
}

export default OptionList;
