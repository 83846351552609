import React from 'react';
import constants from '../../js/APIObjectConstants';
import Introduction from './Introduction';
import {Tab, Tabs, Typography} from '@mui/material';
import DataDictionary from './DataDictionary';

const DataDictionaryPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className='dataDictionary-page-container' style={{width:'80%', margin: 'auto', minHeight:'80vh'}}>
      <Typography variant={'h4'} sx={{marginTop: '1em', marginBottom: '0.5em', textAlign: 'center'}}>Utah's Performance API v1 Data Dictionary</Typography>
      <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example" centered={true} sx={{marginBottom:'2em'}}>
        <Tab label="Introduction"/>
        <Tab label="Data Dictionary"/>
      </Tabs>
      {
        value === 0 ? <Introduction/> : <DataDictionary />
      }
    </div>
  );
}

export default DataDictionaryPage;
